<template>
  <div id="app">
    <div class="bg_green">
      <div class="white_box">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <div class="txb">
            <van-field required v-model="businessCustomerUserName" name="businessCustomerUserName"
                       label="输入姓名"
                       placeholder="请输入您的姓名"
                       :rules="[{ required:true, message: '姓名不能为空' }]"/>
          </div>
          <div class="txb">
            <van-field required v-model="mobile" name="mobile" type="number" maxlength="11" label="手机号码"
                       placeholder="请输入手机号码"
                       :rules="[{ required:true, message: '手机号码不能为空' }]"/>
          </div>
          <div class="txb">
            <!-- 选择学校 -->
            <div class="txb">
              <van-field required ref="content1_2" readonly label="选择学校" v-model="school.name"
                         placeholder="请选择学校"
                         @click="focusSchool"/>
              <div class="txb_icon" @click="focusSchool"><img src="../assets/images/ico_txb1.png" alt="">
              </div>
            </div>
            <div class="txb_icon" @click="focusSchool"><img src="../assets/images/ico_txb1.png" alt="">
            </div>
          </div>
          <van-button :loading="loading" class="button_green" type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>

    <!-- 弹出筛选 -->
    <div class="bottom_select_bg" v-show="select_bg_show" @click="cancelSelect"></div>

    <!-- 弹出筛选学校 -->
    <div class="bottom_select animated fadeInUp" v-show="select1_show">
      <div class="bottom_select_t">
        <div class="bottom_select_t_in">
          <input type="text" ref="content1_1" v-model="searchSchoolKey" placeholder="输入内容可搜索..."/>
        </div>
      </div>
      <div class="bottom_select_b">
        <ul>
          <li v-for="(option, index) in matchedSchoolOptions" :key="index"
              @mousedown="select_bg_show=false, select1_show=false, searchSchoolKey = option, school = option, searchSchoolKey='', structure={}">
            {{ option.name }}
          </li>
        </ul>
        <div class="bottom_select_b_b"></div>
      </div>
    </div>

    <van-popup v-model="employShow">
      <div class="white_box3_in pdb_0">
        <div class="sub_title2">匹配到多位同名用户，请选择一位</div>
        <!-- 用户 -->
        <div v-for="(option, index) in employOptions" :key="index" class="user under_line pd_3p6_0 clear">
          <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
          <div class="user_msg">
            <span>{{ option.name }} （{{ option.alias }}）</span>
            <label>{{ school.name }}</label>
          </div>
          <div class="user_r2 ico iconfont">
            <van-button class="button_green short" :loading="loading" type="info" @click="bind(option.id)">
              选择
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<style scoped>
::v-deep .van-cell::after {
  display: none !important;
}

::v-deep .van-checkbox {
  margin-left: 10px;
}

.van-popup--center {
  width: 86vw;
}

.user_msg {
  width: calc(100% - 102px);
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_TOKEN, ACCESS_Customer} from '@/store/mutation-types'
import {post} from '@/utils/http'
import Notify from "vant/es/notify";
import {Dialog} from 'vant';

export default {
  data() {
    return {
      loading: false,
      img: require('../assets/images/avatar.png'),

      mobile: '',
      businessCustomerUserName: '',
      businessId: '',
      school: {},

      employShow: false,
      employOptions: [],

      schoolOptions: [],
      searchSchoolKey: '',
      select_bg_show: false,
      select1_show: false,

      isOpenAccount: false // 是否已开启了钱包
    };
  },
  mounted() {
    post('api/public/business/v2/searchByAttributionPlatform', {
      size: 500,
      page: 0,
      filters: [
        {name: 'state', op: '=', value: 'ENABLED'},
        {name: "attributionPlatform", op: "=", value: process.env.VUE_APP_ENV == 'production-zsy' ? "ZSY" : "YTSS"}
      ]
    }, false).then(res => {
      res.data.rows.forEach(item => this.schoolOptions.push({id: item.id, name: item.name}));
    })

    post('api/customer/account/v2/find', {}, true).then(res => {
      this.isOpenAccount = res.data != null
    })
  },
  methods: {
    bind(businessCustomerUserId) {
      this.loading = true;
      let accessToken = Vue.ls.get(ACCESS_TOKEN)
      let url = 'api/public/customer/user/v2/customer/user/bind';
      let neetToken = false

      if (Vue.ls.get(ACCESS_Customer)) {
        url = 'api/customer/user/v2/customer/user/bind';
        neetToken = true
      }
      post(url, {
        mobile: this.mobile,
        customerName: this.businessCustomerUserName,
        businessCustomerUserId: businessCustomerUserId,
        accessToken: accessToken
      }, neetToken).then(res => {
        Vue.ls.set(ACCESS_Customer, accessToken, 7 * 24 * 60 * 60 * 1000)
        Dialog.alert({message: '您已成功关联一个新的身份'}).then(() => {
          this.$router.push({path: '/index'})
        });
      }).catch(res => {
        this.loading = false
        Notify({type: 'warning', message: res.message});
      });
    },
    showEmploy() {
      this.employShow = true
    },
    mobileCheck() {
      if (this.mobile.length != 11) {
        return false;
      }
      if (this.mobile.substring(0, 1) != '1') {
        return false;
      }
      return true;
    },
    onSubmit() {
      /*
      * 检查是否重名，重名则弹出进行选择
      * 没有重名则直接提交
      * */
      if (!this.school.id) {
        Notify({type: 'warning', message: '学校不能为空'});
        return;
      }
      if (!this.mobileCheck()) {
        Notify({type: 'warning', message: '手机号码格式输入有误'});
        return;
      }
      this.loading = true;
      post('api/public/business/v2/employ/name/search', {
        name: this.businessCustomerUserName,
        businessId: this.school.id
      }, false).then(res => {
        if (res.data && res.data.length > 0) {
          if (res.data.length === 1) {
            this.bind(res.data[0].id);
          } else {
            // 弹出重名列表进行选择
            this.employOptions = []
            res.data.forEach(item => this.employOptions.push({
              id: item.id,
              name: item.name,
              alias: item.alias
            }))
            this.showEmploy();
          }
        } else {
          // 直接提交
          Notify({type: 'warning', message: '查询不到该教职工的信息'});
        }
        this.loading = false
      }).catch(() => this.loading = false);
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    focusSchool() {
      this.$refs.content1_2.blur()
      this.select_bg_show = true
      this.select1_show = true
    },
    onConfirm(select_value1) {
      this.select_value1 = select_value1;
      this.showPicker = false;
    },
    cancelSelect() {
      this.searchSchoolKey = ''
      this.select_bg_show = false
      this.select1_show = false
    }
  },
  computed: {
    matchedSchoolOptions() {
      if (this.searchSchoolKey !== '') {
        return this.schoolOptions.filter(option => option.name.indexOf(this.searchSchoolKey) != -1)
      }
      return []
    }
  }
}
;
</script>
